<template>
  <v-container fluid fill-height>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <div class="d-flex align-center justify-center">
          <v-btn
            large
            fab
            dark
            @click="openInstrument"
            color="identity"
            v-on="on"
          >
            <v-icon dark> mdi-heart </v-icon>
          </v-btn>
          <div>
            <v-btn
              class="ml-1"
              text
              small
              color="identity"
              @click="openInstrument"
              v-on="on"
            >
              <div v-if="$vuetify.breakpoint.mobile">
                Genera tu pase de entrada<v-icon>mdi-chevron-right</v-icon>
              </div>
              <div v-else>
                Genera tu pase de entrada de hoy<v-icon
                  >mdi-chevron-right</v-icon
                >
              </div>
            </v-btn>
            <div
              v-if="$vuetify.breakpoint.mobile"
              class="d-block text-body-2 text-right identity--text mr-9 pa-0
              text-uppercase"
            >
              de hoy
            </div>
          </div>
        </div>
      </template>

      <template>
        <v-card class="mx-auto" max-width="500" id="main">
          <v-window :touch="{ left: back, right: next }" v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <div class="mt-6 mt-sm-12 mb-6 mb-sm-12 px-4 px-sm-8">
                  <v-row>
                    <v-col cols="12">
                      <v-img
                        contain
                        :src="
                          require('@/assets/henkel-logo-standalone-svg.svg')
                        "
                        width="80"
                      ></v-img>
                    </v-col>
                    <v-col cols="12">
                      <h4
                        class="mb-10 text-h5 identity--text font-weight-bold text-uppercase"
                      >
                        !Hola!
                      </h4>
                    </v-col>
                  </v-row>

                  <h6 class="mb-12 text-body-1 grey-text text-darken-4">
                    Estás a punto de realizar una valoración breve (3 min). Al
                    finalizar te presentaremos algunas sugerencias si fueran
                    necesarias.
                  </h6>
                  <p class="mt-3 grey--text text--darken-2">
                    Recuerda que llenarás esta entrevista cada que salgas de tu
                    casa a laborar.
                  </p>
                </div>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <div class="mt-6 mt-sm-12 mb-6 px-4 px-sm-8">
                  <h4
                    class="mb-10 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    Términos
                  </h4>
                  <h6 class="mb-6 text-body-1 grey-text text-darken-4">
                    Antes de continuar, por favor, lee los términos de uso de
                    este instrumento. Recuerda que:
                  </h6>
                  <ul class="mx-0 mx-sm-4">
                    <li class="mb-4">
                      <span class="font-weight-medium">Compromiso.</span>
                      Necesitas contestar esta entrevista desde casa, previo a
                      salir a laborar, y responder con claridad y honestidad.
                    </li>
                    <li class="mb-4">
                      <span class="font-weight-medium">Salud.</span> Esta
                      valoración tiene la finalidad de reducir los contagios
                      internos en la planta, y por tanto, los resultados son
                      sólo informativos y no sustituyen la opinión de un médico.
                    </li>
                    <li>
                      <span class="font-weight-medium"
                        >Tus datos están a salvo.</span
                      >
                      La información que proporciones sólo es utilizada por este
                      instrumento para tu acceso a la planta y para las
                      notificaciones a los responsables de salvaguardar la salud
                      dentro de ésta.
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <div class="mt-6 mt-sm-12 mb-6 mb-sm-12 px-4 px-sm-8">
                  <h4
                    class="mb-10 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    Identificación
                  </h4>
                  <v-container class="pa-0">
                    <v-form
                      ref="form-name"
                      v-model="isValidName"
                      @submit.prevent="next"
                    >
                      <v-row no-gutters class="mb-6">
                        <v-col cols="12">
                          <h6
                            class="text-h5 grey--text text--darken-4 text-uppercase text-left"
                          >
                            Nombre
                          </h6>

                          <v-text-field
                            v-model="name"
                            :label="'Nombre completo'"
                            autofocus
                            type="text"
                            prepend-icon="mdi-account"
                            required
                            :rules="rules.name"
                            @keyup="capitalizeName"
                            @keyup.enter="focusAreaField"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h6
                            class="text-h5 grey--text text--darken-4 text-uppercase text-left"
                          >
                            Área
                          </h6>

                          <v-text-field
                            ref="fieldArea"
                            v-model="aname"
                            :label="'Área de trabajo'"
                            type="text"
                            prepend-icon="mdi-grain"
                            required
                            :rules="rules.area"
                            @keyup="capitalizeArea"
                            @keyup.enter="next"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </div>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="4">
              <v-card-text>
                <div class="mt-6 mt-sm-12 mb-6 mb-sm-8 px-4 px-sm-8">
                  <h4
                    class="mb-10 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    Identificación
                  </h4>
                  <v-form ref="form-company" v-model="valid">
                    <v-container class="pa-0">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h6
                            class="text-h5 grey--text text--darken-4 text-uppercase text-left"
                          >
                            Compañía
                          </h6>
                          <p class="mb-6 text-left">
                            Por favor, selecciona una compañía.
                          </p>
                          <pub-orgs-cards
                            class="mt-6 mx-auto"
                            style="max-width: 425px"
                            :initOu="oid"
                            :withHeadings="false"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="5">
              <div class="px-8 px-sm-12">
                <h5
                  class="mt-6 mt-sm-12 mb-1 mb-sm-10 text-h5 text-sm-h4 text-md-h5 text-uppercase text-right text-sm-left"
                >
                  Por favor responde con honestidad
                </h5>

                <h4
                  class="text-h5 identity--text font-weight-bold text-uppercase"
                >
                  ¿Cuál es tu temperatura corporal?
                </h4>

                <p class="mb-6">
                  Temperatura en casa.
                </p>

                <v-card class="mx-auto" max-width="500">
                  <v-card-text class="mx-0 px-0 my-1 py-1">
                    <v-list two-line>
                      <v-list-item-group
                        v-model="temperatureSelected"
                        active-class="grey--text text--lighten-2"
                      >
                        <template v-for="(item, index) in temperatureItems">
                          <v-list-item :key="item.title" dense>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-icon v-if="!active" color="grey lighten-1">
                                  mdi-checkbox-blank-circle-outline
                                </v-icon>

                                <v-icon v-else :color="item.semaphore">
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.title"
                                  class="text-body-1 black--text font-weight-medium"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                  v-html="item.headline"
                                  class="text--primary"
                                ></v-list-item-subtitle>

                                <v-list-item-subtitle
                                  v-html="item.subtitle"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < temperatureItems.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </div>
              <!-- Help Desk -->
              <v-container fluid class="pb-6 pb-sm-12">
                <v-row align="center" justify="space-around">
                  <v-btn
                    small
                    text
                    color="grey"
                    @click="temperatureReportDialog = !temperatureReportDialog"
                    >¿dificultades con la pregunta?</v-btn
                  >
                  <v-dialog v-model="temperatureReportDialog" width="500">
                    <v-card class="mx-auto" max-width="500">
                      <v-card-text>
                        <h6
                          class="pt-8 mb-8 text-h5 grey--text text--darken-4 text-center"
                        >
                          ¿Qué problema identificas con la pregunta?
                          <v-icon color="yellow darken-2"
                            >mdi-lightbulb-on-outline</v-icon
                          >
                        </h6>
                        <v-card class="mx-auto" max-width="500">
                          <v-list two-line>
                            <v-list-item-group
                              v-model="reportSelected"
                              active-class="yellow--text text--darken-2"
                              multiple
                              mandatory
                            >
                              <template v-for="(item, index) in reportItems">
                                <v-list-item :key="item.title">
                                  <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                      <v-icon
                                        v-if="!active"
                                        color="grey lighten-1"
                                      >
                                        mdi-checkbox-blank-outline
                                      </v-icon>

                                      <v-icon v-else :color="item.semaphore">
                                        mdi-check
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="item.title"
                                        class="text-h6"
                                      ></v-list-item-title>

                                      <v-list-item-subtitle
                                        v-html="item.headline"
                                        class="text--primary"
                                      ></v-list-item-subtitle>

                                      <v-list-item-subtitle
                                        v-html="item.subtitle"
                                      ></v-list-item-subtitle>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>

                                <v-divider
                                  v-if="index < reportItems.length - 1"
                                  :key="index"
                                ></v-divider>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-card-text>
                      <v-card-actions>
                        <v-list-item class="grow">
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-btn
                                text
                                @click="
                                  temperatureReportDialog = false
                                  cancelReport()
                                "
                              >
                                Cancelar
                              </v-btn></v-list-item-title
                            >
                          </v-list-item-content>

                          <v-btn
                            class="white--text"
                            color="yellow darken-2"
                            @click="temperatureReportDialog = false"
                          >
                            Reportar
                          </v-btn>
                        </v-list-item>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-container>
            </v-window-item>

            <v-window-item :value="6">
              <v-card>
                <v-card-text class="px-6 px-sm-12">
                  <h4
                    class="mt-6 mt-sm-12 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    ¿Tienes alguno de los siguientes síntomas?
                  </h4>
                  <p class="mb-6">
                    Por favor, escoge una respuesta para cada sentencia.
                  </p>

                  <v-card class="mx-auto" max-width="500">
                    <v-list two-line>
                      <v-list-item-group
                        v-model="symptomSelected"
                        active-class="white"
                        mandatory
                        multiple
                      >
                        <template v-for="(item, index) in symptomsItems">
                          <v-list-item
                            :key="item.title"
                            color="white"
                            dense
                            :ripple="false"
                          >
                            <template v-slot:default="{}">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.title"
                                  class="text-body-1 black--text"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                  v-html="item.headline"
                                  class="text--primary"
                                ></v-list-item-subtitle>

                                <v-list-item-subtitle
                                  v-text="item.subtitle"
                                ></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-radio-group v-model="item.yes" row dense>
                                  <v-radio label="Si" :value="true"></v-radio>
                                  <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < symptomsItems.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-card-text>
                <!-- Help Desk -->
                <v-container fluid class="pb-6 pb-sm-12">
                  <v-row align="center" justify="space-around">
                    <v-btn
                      small
                      text
                      color="grey"
                      @click="symptomReportDialog = !symptomReportDialog"
                      >¿dificultades con la pregunta?</v-btn
                    >
                    <v-dialog v-model="symptomReportDialog" width="500">
                      <v-card class="mx-auto" max-width="500">
                        <v-card-text>
                          <h6
                            class="pt-8 mb-8 text-h5 grey--text text--darken-4 text-center"
                          >
                            ¿Qué problema identificas con la pregunta?
                            <v-icon color="yellow darken-2"
                              >mdi-lightbulb-on-outline</v-icon
                            >
                          </h6>
                          <v-card class="mx-auto" max-width="500">
                            <v-list two-line>
                              <v-list-item-group
                                v-model="reportSelected"
                                active-class="yellow--text text--darken-2"
                                multiple
                              >
                                <template v-for="(item, index) in reportItems">
                                  <v-list-item :key="item.title">
                                    <template v-slot:default="{ active }">
                                      <v-list-item-action>
                                        <v-icon
                                          v-if="!active"
                                          color="grey lighten-1"
                                        >
                                          mdi-checkbox-blank-outline
                                        </v-icon>

                                        <v-icon v-else :color="item.semaphore">
                                          mdi-check
                                        </v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="item.title"
                                          class="text-h6"
                                        ></v-list-item-title>

                                        <v-list-item-subtitle
                                          v-html="item.headline"
                                          class="text--primary"
                                        ></v-list-item-subtitle>

                                        <v-list-item-subtitle
                                          v-html="item.subtitle"
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>

                                  <v-divider
                                    v-if="index < reportItems.length - 1"
                                    :key="index"
                                  ></v-divider>
                                </template>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-list-item class="grow">
                            <v-list-item-content>
                              <v-list-item-title
                                ><v-btn
                                  text
                                  @click="
                                    symptomReportDialog = false
                                    cancelReport()
                                  "
                                >
                                  Cancelar
                                </v-btn></v-list-item-title
                              >
                            </v-list-item-content>
                            <v-btn
                              class="white--text"
                              color="yellow darken-2"
                              @click="symptomReportDialog = false"
                            >
                              Reportar
                            </v-btn>
                          </v-list-item>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-container>
              </v-card>
            </v-window-item>

            <v-window-item :value="7">
              <v-card>
                <v-card-text class="px-6 px-sm-12">
                  <h4
                    class="mt-6 mt-sm-12 mb-6 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    ¿Consideras que los síntomas actuales?
                  </h4>

                  <v-card class="mx-auto" max-width="500">
                    <v-list two-line>
                      <v-list-item-group
                        active-class="white"
                        v-model="vaccineSelected"
                        multiple
                      >
                        <template v-for="(item, index) in vaccineItems">
                          <v-list-item
                            :key="item.title"
                            color="white"
                            dense
                            :ripple="false"
                          >
                            <template v-slot:default="{}">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.title"
                                  class="text-body-1 black--text"
                                ></v-list-item-title>

                                <v-list-item-subtitle
                                  class="text--primary"
                                  v-html="item.headline"
                                ></v-list-item-subtitle>

                                <v-list-item-subtitle
                                  v-text="item.subtitle"
                                ></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action class="ml-2">
                                <v-radio-group v-model="item.yes" row dense>
                                  <v-radio label="Si" :value="true"></v-radio>
                                  <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                              </v-list-item-action>
                            </template>
                          </v-list-item>

                          <v-divider
                            v-if="index < vaccineItems.length - 1"
                            :key="index"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>

                    <div v-if="isVaccinated" class="mx-4 pb-8">
                      <v-menu
                        ref="menuVaccine"
                        v-model="menuVaccine"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-row>
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mt-1 ml-2"
                            >
                              <v-icon>mdi-calendar</v-icon>
                            </v-btn>
                            <v-text-field
                              class="ml-3"
                              hide-details="true"
                              v-model="formattedDateVaccine"
                              label="Selecciona fecha de aplicación de dosis"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </v-row>
                        </template>
                        <v-date-picker
                          v-model="vaccineDate"
                          :active-picker.sync="activeVaccinePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="2021-01-01"
                          @change="saveVaccine"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-card>
                </v-card-text>
                <!-- Help Desk -->
                <v-container fluid class="pb-6 pb-sm-12">
                  <v-row align="center" justify="space-around">
                    <v-btn
                      small
                      text
                      color="grey"
                      @click="vaccineReportDialog = !vaccineReportDialog"
                      >¿dificultades con la pregunta?</v-btn
                    >
                    <v-dialog v-model="vaccineReportDialog" width="500">
                      <v-card class="mx-auto" max-width="500">
                        <v-card-text>
                          <h6
                            class="pt-8 mb-8 text-h5 grey--text text--darken-4 text-center"
                          >
                            ¿Qué problema identificas con la pregunta?
                            <v-icon color="yellow darken-2"
                              >mdi-lightbulb-on-outline</v-icon
                            >
                          </h6>
                          <v-card class="mx-auto" max-width="500">
                            <v-list two-line>
                              <v-list-item-group
                                v-model="reportSelected"
                                active-class="yellow--text text--darken-2"
                                multiple
                              >
                                <template v-for="(item, index) in reportItems">
                                  <v-list-item :key="item.title">
                                    <template v-slot:default="{ active }">
                                      <v-list-item-action>
                                        <v-icon
                                          v-if="!active"
                                          color="grey lighten-1"
                                        >
                                          mdi-checkbox-blank-outline
                                        </v-icon>

                                        <v-icon v-else :color="item.semaphore">
                                          mdi-check
                                        </v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="item.title"
                                          class="text-h6"
                                        ></v-list-item-title>

                                        <v-list-item-subtitle
                                          v-html="item.headline"
                                          class="text--primary"
                                        ></v-list-item-subtitle>

                                        <v-list-item-subtitle
                                          v-html="item.subtitle"
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>

                                  <v-divider
                                    v-if="index < reportItems.length - 1"
                                    :key="index"
                                  ></v-divider>
                                </template>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-list-item class="grow">
                            <v-list-item-content>
                              <v-list-item-title
                                ><v-btn
                                  text
                                  @click="
                                    vaccineReportDialog = false
                                    cancelReport()
                                  "
                                >
                                  Cancelar
                                </v-btn></v-list-item-title
                              >
                            </v-list-item-content>

                            <v-btn
                              class="white--text"
                              color="yellow darken-2"
                              @click="vaccineReportDialog = false"
                            >
                              Reportar
                            </v-btn>
                          </v-list-item>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-container>
              </v-card>
            </v-window-item>

            <v-window-item :value="8">
              <v-card>
                <v-card-text class="px-6 px-sm-12">
                  <h4
                    class="mt-6 mt-sm-12 text-h5 identity--text font-weight-bold text-uppercase"
                  >
                    ¿En los últimos 14 días?
                  </h4>

                  <p class="mb-6">
                    Por favor, selecciona las opciones que apliquen.
                  </p>

                  <v-card class="mx-auto" max-width="500">
                    <v-card-text class="mx-0 px-0 my-1 py-1">
                      <v-list two-line>
                        <v-list-item-group
                          v-model="lifestyleSelected"
                          active-class="primary--text"
                          multiple
                        >
                          <template v-for="(item, index) in lifestyleItems">
                            <v-list-item :key="item.title">
                              <template v-slot:default="{ active }">
                                <v-list-item-action>
                                  <v-icon v-if="!active" color="grey lighten-1">
                                    mdi-checkbox-blank-outline
                                  </v-icon>

                                  <v-icon v-else :color="item.semaphore">
                                    mdi-check
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="item.title"
                                    class="text-body-1 black--text font-weight-medium"
                                  ></v-list-item-title>

                                  <v-list-item-subtitle
                                    v-html="item.headline"
                                    class="text--primary"
                                  ></v-list-item-subtitle>

                                  <v-list-item-subtitle
                                    v-html="item.subtitle"
                                  ></v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="index < lifestyleItems.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <!-- Help Desk -->
                <v-container fluid class="pb-6 pb-sm-12">
                  <v-row align="center" justify="space-around">
                    <v-btn
                      small
                      text
                      color="grey"
                      @click="lifestyleReportDialog = !lifestyleReportDialog"
                      >¿dificultades con la pregunta?</v-btn
                    >
                    <v-dialog v-model="lifestyleReportDialog" width="500">
                      <v-card class="mx-auto" max-width="500">
                        <v-card-text>
                          <h6
                            class="pt-8 mb-8 text-h5 grey--text text--darken-4 text-center"
                          >
                            ¿Qué problema identificas con la pregunta?
                            <v-icon color="yellow darken-2"
                              >mdi-lightbulb-on-outline</v-icon
                            >
                          </h6>
                          <v-card class="mx-auto" max-width="500">
                            <v-list two-line>
                              <v-list-item-group
                                v-model="reportSelected"
                                active-class="yellow--text text--darken-2"
                                multiple
                              >
                                <template v-for="(item, index) in reportItems">
                                  <v-list-item :key="item.title">
                                    <template v-slot:default="{ active }">
                                      <v-list-item-action>
                                        <v-icon
                                          v-if="!active"
                                          color="grey lighten-1"
                                        >
                                          mdi-checkbox-blank-outline
                                        </v-icon>

                                        <v-icon v-else :color="item.semaphore">
                                          mdi-check
                                        </v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="item.title"
                                          class="text-h6"
                                        ></v-list-item-title>

                                        <v-list-item-subtitle
                                          v-html="item.headline"
                                          class="text--primary"
                                        ></v-list-item-subtitle>

                                        <v-list-item-subtitle
                                          v-html="item.subtitle"
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>

                                  <v-divider
                                    v-if="index < reportItems.length - 1"
                                    :key="index"
                                  ></v-divider>
                                </template>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-list-item class="grow">
                            <v-list-item-content>
                              <v-list-item-title
                                ><v-btn
                                  text
                                  @click="
                                    lifestyleReportDialog = false
                                    cancelReport()
                                  "
                                >
                                  Cancelar
                                </v-btn></v-list-item-title
                              >
                            </v-list-item-content>

                            <v-btn
                              class="white--text"
                              color="yellow darken-2"
                              @click="lifestyleReportDialog = false"
                            >
                              Reportar
                            </v-btn>
                          </v-list-item>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-container>
                <v-card>
                  <v-card-title
                    class="mx-6 text-uppercase grey--text text--darken-4 text-center"
                  >
                    {{ name }}, ¿das por manifiesto que las respuestas
                    contestadas son verídicas?
                  </v-card-title>
                </v-card>
              </v-card>
            </v-window-item>

            <v-window-item :value="9">
              <v-card-text>
                <div class="mt-6 mt-sm-12 mb-6 mb-sm-12 mx-6 mx-sm-12">
                  <!-- Results headings -->
                  <h4
                    class="text-h5 identity--text font-weight-bold text-uppercase"
                    :class="passCodeCase ? 'mb-0 text-right' : 'mb-10'"
                  >
                    {{ resultsTitle }}
                  </h4>

                  <div v-if="isRequestAllowed && passCodeCase">
                    <!-- Party -->
                    <div>
                      <h1
                        class="text-h4 grey--text text--darken-4 text-uppercase text-right"
                      >
                        en {{ orgName }}
                      </h1>
                      <h1
                        class="mb-10 text-h5 text-right grey--text text-uppercase text--darken-4"
                      >
                        {{ aname }}
                      </h1>
                      <h1
                        class="text-h6 grey--text text--darken-4 text-uppercase text-left"
                      >
                        {{ name }}
                      </h1>
                    </div>

                    <!-- Pass Code -->
                    <div>
                      <h6
                        class="mb-1 text-h5 text-left text-uppercase"
                        :class="semaphoreColor"
                      >
                        TU CÓDIGO DE INGRESO
                      </h6>
                      <div class="d-flex justify-center">
                        <v-avatar
                          class="outlined"
                          size="125"
                          @click="openQRCode"
                        >
                          <v-img :src="pass.pass"></v-img>
                        </v-avatar>
                      </div>
                      <h6
                        class="mb-4 text-h3 text-sm-h2 text-center text-uppercase spaced-numbers"
                      >
                        {{ pass.code }}
                      </h6>
                      <h6
                        class="mb-6 text-h5 green--text text--lighten-2 text-uppercase text-right"
                      >
                        válido sólo por hoy {{ today() }}
                      </h6>
                      <h6
                        class="text-body-1 grey--text text--darken-4 text-uppercase text-center"
                      >
                        DESDE TU CELULAR PRESENTA ESTA PANTALLA. TAMBIÉN PUEDES
                        TOMARLE UNA FOTO O CAPTURARLA.
                      </h6>
                      <h6
                        class="mb-12 text-body-2 grey--text text--darken-4 text-center"
                      >
                        Si llegaras a cerrar esta ventana, sólo ábrela de nuevo
                        y aparecerá tu código vigente.
                      </h6>
                      <v-expansion-panels class="mt-5">
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <span class="text-left">
                              Ver recomendaciones
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card
                              class="mx-auto pa-0 mb-md-8"
                              max-width="500"
                            >
                              <v-list two-line>
                                <v-list-item-group>
                                  <template
                                    v-for="(item,
                                    index) in healthyRecomendationsItems"
                                  >
                                    <v-list-item disabled :key="item.title">
                                      <template>
                                        <v-list-item-action>
                                          <v-list-item-action-text
                                            class="text-h5 ml-2"
                                            v-html="item.action"
                                          ></v-list-item-action-text>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            class="grey--text text--darken-3 font-weight-medium"
                                            v-html="item.title"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>

                                    <v-divider
                                      v-if="
                                        index <
                                          healthyRecomendationsItems.length - 1
                                      "
                                      :key="index"
                                    ></v-divider>
                                  </template>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </div>

                  <!-- Vaccine effects -->
                  <div
                    v-else-if="
                      isRequestAllowed && (vaccineCase || isSavedVaccineCase)
                    "
                  >
                    <h6
                      class="mb-1 text-h5 text-sm-h4 text-left text-uppercase"
                    >
                      Es probable que tengas los efectos de la vacuna
                    </h6>
                    <p class="mb-6">
                      Por favor, sigue las siguientes recomendaciones.
                    </p>

                    <v-card class="mx-auto" max-width="500">
                      <v-list two-line>
                        <v-list-item-group>
                          <template
                            v-for="(item,
                            index) in vaccineEffectsRecomendationsItems"
                          >
                            <v-list-item
                              :disabled="!item.phoneRequired"
                              :key="item.title"
                            >
                              <template>
                                <v-list-item-action v-if="!item.phoneRequired">
                                  <v-list-item-action-text
                                    class="text-h5 ml-2"
                                    v-html="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="grey--text text--darken-3 font-weight-medium"
                                    v-html="item.title"
                                  ></v-list-item-title>
                                  <v-form
                                    v-if="item.phoneRequired"
                                    ref="form-mobile"
                                    v-model="isValidPhone"
                                    @submit.prevent="next"
                                  >
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="4">
                                          <v-text-field
                                            :label="'Código'"
                                            v-model="code"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="[\+]\d{3}[ ]?"
                                            autocomplete="off"
                                            maxlength="4"
                                            prepend-inner-icon="mdi-flag-variant-outline"
                                            required
                                            value="+52"
                                            :rules="rules.optionalIntlCode"
                                            @click="defaultCode($event)"
                                            @keyup="defaultCode($event)"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                          <v-text-field
                                            :label="'Número del móvil'"
                                            v-model="phone"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="\d{10}"
                                            autocomplete="off"
                                            maxlength="10"
                                            required
                                            prepend-inner-icon="mdi-cellphone-message"
                                            :rules="rules.phone"
                                            @keyup.enter="next"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-form>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="
                                index <
                                  vaccineEffectsRecomendationsItems.length - 1
                              "
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </div>

                  <!-- Probably Covid19 -->
                  <div
                    v-else-if="
                      isRequestAllowed &&
                        ((probablyCovidCase && !hasAssessment) ||
                          isSavedProbablyCovidCase)
                    "
                  >
                    <h6
                      class="mb-1 text-h5 text-sm-h4 text-left text-uppercase"
                    >
                      Los síntomas pueden estar causados por el COVID-19
                    </h6>
                    <p class="mb-6">
                      Te sugerimos aislarte y podrás regresar a tus labores
                      cuando se cumplan algunas de las siguientes condiciones y
                      recomendaciones.
                    </p>

                    <v-card class="mx-auto" max-width="500">
                      <v-list two-line>
                        <v-list-item-group>
                          <template
                            v-for="(item,
                            index) in noHealthyRecomendationsItems"
                          >
                            <v-list-item
                              :disabled="!item.phoneRequired"
                              :key="item.title"
                            >
                              <template>
                                <v-list-item-action v-if="!item.phoneRequired">
                                  <v-list-item-action-text
                                    class="text-h5 ml-2"
                                    v-html="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="grey--text text--darken-3 font-weight-medium"
                                    :class="item.phoneRequired ? 'ml-2' : ''"
                                    v-html="item.title"
                                  ></v-list-item-title>
                                  <v-form
                                    v-if="item.phoneRequired"
                                    ref="form-mobile"
                                    v-model="isValidPhone"
                                    @submit.prevent="next"
                                  >
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="4">
                                          <v-text-field
                                            :label="'Código'"
                                            v-model="code"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="[\+]\d{3}[ ]?"
                                            autocomplete="off"
                                            maxlength="4"
                                            prepend-inner-icon="mdi-flag-variant-outline"
                                            required
                                            value="+52"
                                            :rules="rules.optionalIntlCode"
                                            @click="defaultCode($event)"
                                            @keyup="defaultCode($event)"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                          <v-text-field
                                            :label="'Número del móvil'"
                                            v-model="phone"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="\d{10}"
                                            autocomplete="off"
                                            maxlength="10"
                                            required
                                            prepend-inner-icon="mdi-cellphone-message"
                                            :rules="rules.phone"
                                            @keyup.enter="next"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-form>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="
                                index < noHealthyRecomendationsItems.length - 1
                              "
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </div>

                  <!-- Contact -->
                  <div
                    v-else-if="
                      isRequestAllowed && (contactCase || isSavedContactCase)
                    "
                  >
                    <h6
                      class="mb-1 text-h5 text-sm-h4 text-left text-uppercase"
                    >
                      Por protocolo de acceso a la planta en caso de haber
                      tenido contacto directo con una persona infectada
                    </h6>
                    <p class="mb-6">
                      Te sugerimos aislarte y podrás regresar a tus labores
                      cuando se cumplan algunas de las siguientes condiciones.
                    </p>

                    <v-card class="mx-auto" max-width="500">
                      <v-list two-line>
                        <v-list-item-group>
                          <template
                            v-for="(item,
                            index) in inContactRiskRecomendationsItems"
                          >
                            <v-list-item
                              :disabled="!item.phoneRequired"
                              :key="item.title"
                            >
                              <template>
                                <v-list-item-action v-if="!item.phoneRequired">
                                  <v-list-item-action-text
                                    class="text-h5 ml-2"
                                    v-html="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="grey--text text--darken-3 font-weight-medium"
                                    :class="item.phoneRequired ? 'ml-2' : ''"
                                    v-html="item.title"
                                  ></v-list-item-title>
                                  <v-form
                                    v-if="item.phoneRequired"
                                    ref="form-mobile"
                                    v-model="isValidPhone"
                                    @submit.prevent="next"
                                  >
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="4">
                                          <v-text-field
                                            :label="'Código'"
                                            v-model="code"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="[\+]\d{3}[ ]?"
                                            autocomplete="off"
                                            maxlength="4"
                                            prepend-inner-icon="mdi-flag-variant-outline"
                                            required
                                            value="+52"
                                            :rules="rules.optionalIntlCode"
                                            @click="defaultCode($event)"
                                            @keyup="defaultCode($event)"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                          <v-text-field
                                            :label="'Número del móvil'"
                                            v-model="phone"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="\d{10}"
                                            autocomplete="off"
                                            maxlength="10"
                                            required
                                            prepend-inner-icon="mdi-cellphone-message"
                                            :rules="rules.phone"
                                            @keyup.enter="next"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-form>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="
                                index <
                                  inContactRiskRecomendationsItems.length - 1
                              "
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </div>

                  <!-- Traveling -->
                  <div
                    v-else-if="
                      isRequestAllowed &&
                        (travelingCase || isSavedTravelingCase)
                    "
                  >
                    <h6
                      class="mb-1 text-h5 text-sm-h4 text-left text-uppercase"
                    >
                      Por protocolo de acceso a la planta en casos de viaje
                    </h6>
                    <p class="mb-6">
                      Te sugerimos aislarte y podrás regresar a tus labores
                      cuando se cumplan algunas de las siguientes condiciones.
                    </p>

                    <v-card class="mx-auto" max-width="500">
                      <v-list two-line>
                        <v-list-item-group>
                          <template
                            v-for="(item,
                            index) in inTravelingRiskRecomendationsItems"
                          >
                            <v-list-item
                              :disabled="!item.phoneRequired"
                              :key="item.title"
                            >
                              <template>
                                <v-list-item-action v-if="!item.phoneRequired">
                                  <v-list-item-action-text
                                    class="text-h5 ml-2"
                                    v-html="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="grey--text text--darken-3 font-weight-medium"
                                    v-html="item.title"
                                  ></v-list-item-title>
                                  <v-form
                                    v-if="item.phoneRequired"
                                    ref="form-mobile"
                                    v-model="isValidPhone"
                                    @submit.prevent="next"
                                  >
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="4">
                                          <v-text-field
                                            :label="'Código'"
                                            v-model="code"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="[\+]\d{3}[ ]?"
                                            autocomplete="off"
                                            maxlength="4"
                                            prepend-inner-icon="mdi-flag-variant-outline"
                                            required
                                            value="+52"
                                            :rules="rules.optionalIntlCode"
                                            @click="defaultCode($event)"
                                            @keyup="defaultCode($event)"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                          <v-text-field
                                            :label="'Número del móvil'"
                                            v-model="phone"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="\d{10}"
                                            autocomplete="off"
                                            maxlength="10"
                                            required
                                            prepend-inner-icon="mdi-cellphone-message"
                                            :rules="rules.phone"
                                            @keyup.enter="next"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-form>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="
                                index <
                                  inTravelingRiskRecomendationsItems.length - 1
                              "
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </div>

                  <!-- Contact & Traveling -->
                  <div
                    v-else-if="
                      isRequestAllowed &&
                        (contactAndTravelingCase ||
                          isSavedContactAndTravelingCase)
                    "
                  >
                    <h6
                      class="mb-1 text-h5 text-sm-h4 text-left text-uppercase"
                    >
                      Por protocolo de acceso a la planta en caso de identificar
                      riesgos de infección
                    </h6>
                    <p class="mb-6">
                      Te sugerimos aislarte y podrás regresar a tus labores
                      cuando se cumplan algunas de las siguientes condiciones.
                    </p>

                    <v-card class="mx-auto" max-width="500">
                      <v-list two-line>
                        <v-list-item-group>
                          <template
                            v-for="(item,
                            index) in inLifestyleRiskRecomendationsItems"
                          >
                            <v-list-item
                              :disabled="!item.phoneRequired"
                              :key="item.title"
                            >
                              <template>
                                <v-list-item-action v-if="!item.phoneRequired">
                                  <v-list-item-action-text
                                    class="text-h5 ml-2"
                                    v-html="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="grey--text text--darken-3 font-weight-medium"
                                    v-html="item.title"
                                  ></v-list-item-title>
                                  <v-form
                                    v-if="item.phoneRequired"
                                    ref="form-mobile"
                                    v-model="isValidPhone"
                                    @submit.prevent="next"
                                  >
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="4">
                                          <v-text-field
                                            :label="'Código'"
                                            v-model="code"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="[\+]\d{3}[ ]?"
                                            autocomplete="off"
                                            maxlength="4"
                                            prepend-inner-icon="mdi-flag-variant-outline"
                                            required
                                            value="+52"
                                            :rules="rules.optionalIntlCode"
                                            @click="defaultCode($event)"
                                            @keyup="defaultCode($event)"
                                          />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                          <v-text-field
                                            :label="'Número del móvil'"
                                            v-model="phone"
                                            :disabled="isCallRequestSet"
                                            type="tel"
                                            pattern="\d{10}"
                                            autocomplete="off"
                                            maxlength="10"
                                            required
                                            prepend-inner-icon="mdi-cellphone-message"
                                            :rules="rules.phone"
                                            @keyup.enter="next"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-form>
                                </v-list-item-content>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="
                                index <
                                  inLifestyleRiskRecomendationsItems.length - 1
                              "
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </div>

                  <!-- Request not allowed by time -->
                  <div v-else-if="!isRequestAllowed">
                    <!-- Wait a minute -->
                    <v-card class="mt-12 pa-6" shaped>
                      <v-card-title class="text-h4 text-uppercase"
                        >Por protocolo</v-card-title
                      >
                      <v-card-subtitle class="text-h5"
                        ><v-icon>mdi-calendar-lock-outline</v-icon> Sólo puedes
                        generar una solicitud por día.</v-card-subtitle
                      >
                      <v-card-subtitle
                        >Por favor espera, para intentarlo de
                        nuevo.</v-card-subtitle
                      >
                    </v-card>
                  </div>
                </div>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn v-if="step > 1 && !isResultsView" text @click="back">
              <v-icon>mdi-chevron-left</v-icon>
              Atrás
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn fab dark fixed bottom right color="primary" @click="toTop"
              ><v-icon>mdi-arrow-up</v-icon>
            </v-btn> -->

            <v-btn
              :class="isPreviousToResultsView ? 'ml-4' : ''"
              :disabled="!isNextEnabled"
              :block="this.isStep('results')"
              color="primary"
              depressed
              @click="next"
              ><v-icon
                class="mr-1"
                v-if="canGenerateCurrentPassCode && isStep('results')"
                >mdi-clock-time-one</v-icon
              >
              <span
                style="max-width:320px;"
                class="d-inline-block text-truncate"
                >{{ nextLabel }}</span
              >
            </v-btn>
            <v-spacer v-if="this.isStep('results')"></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="requestLoading" hide-overlay persistent width="115">
      <!-- <div
        class="d-flex justify-center mt-12"
        v-if="progressCircularValue > 0"
      > -->
      <v-progress-circular
        class="ma-2"
        :rotate="-90"
        :size="100"
        :width="15"
        :value="progressCircularValue"
        color="black"
      >
        {{ progressCircularValue }}
      </v-progress-circular>
      <!-- </div> -->
    </v-dialog>
    <code-dialog
      ref="qrcodedialog"
      :party="party"
      :resource="pass"
    ></code-dialog>
    <v-snackbar
      content-class="text-h6 mx-1 mt-5 text-center"
      color="yellow darken-2"
      centered
      vertical
      timeout="10000"
      v-model="requestForCallAck.sent"
    >
      {{ requestForCallAck.title }}
      <v-icon small color="primary">mdi-send</v-icon>
      <p class="text-caption">{{ requestForCallAck.message }}</p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { humanFormattedDate, longFormattedDate } from '@/micro/time/dateFormat'
import { mapGetters, mapActions } from 'vuex'
import rules from '@/micro/app/generalRules.js'
import PubOrgsCards from '@/components/orgs/PubOrgsCards'
import { isDef } from '@/micro/utils'
import { subDays, isAfter, format } from 'date-fns/fp'
import { defaultValue } from '@/micro/field'
import { remainingTimeToNewPass } from './requestsOperators/codeTimeCtl'
import { firebaseAuth } from '@/firebaseInit'
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { enablePersistence } from '@/fbApi'
import { transformToVersales } from '@/micro/text'
import {
  isHeadache,
  isRunnyNose,
  isMalaise
} from '@/components/requestsOperators/conditionsAssessments'
import { authResult } from '@/components/requestsOperators/assessmentCtl'
import CodeDialog from '@/components/requestsOperators/CodeDialog.vue'

const TOTAL_STEPS = 9
const ONE_SECOND = 1000
// const ONE_MINUTE = ONE_SECOND * 60
// const FIVE_MINUTES = ONE_MINUTE * 5
// const LONG_UPDATES_FRECUENCY = ONE_MINUTE * 10
// const SHORT_UPDATES_FRECUENCY = ONE_MINUTE
const REALTIME_UPDATES_FRECUENCY = ONE_SECOND
// const TIME_ZONE = 'America/Mexico_City'

export default {
  components: {
    PubOrgsCards,
    CodeDialog
  },
  data() {
    return {
      // GUI basics
      checked: false,
      dialog: false,
      fab: false,
      step: 1,

      // GUI Time basics
      progressCircularValue: 0,
      interval: null,
      now: null,

      // GUI Domain
      temperatureReportDialog: false,
      symptomReportDialog: false,
      allSymptomsChecked: false,
      vaccineReportDialog: false,
      allVaccineChecked: false,
      lifestyleReportDialog: false,

      // GUI Date picker
      activeVaccinePicker: 'YEAR',
      menuVaccine: false,

      // Domain policies
      isRequestAllowed: true,
      waitingForNextCycle: false,
      terms: false,

      // Domain params
      temperature: [],
      vaccineDate: null,
      isVaccinated: false,
      temperatureSelected: null,
      symptomSelected: [],
      hasNoSymptoms: false,
      vaccineSelected: [],
      lifestyleSelected: [],
      hasHeadache: null,
      hasRunnyNose: null,
      hasMalaise: null,

      // Domain helpers
      // ---- Continuos improvement
      reportSelected: [],
      // ---- Help desk
      requestForCallAck: {
        sent: false,
        failure: false,
        title: 'Solicitud enviada',
        message: 'Servicios médicos se comunicará contigo.'
      },

      // Input validation
      isValidPhone: false,
      isValidName: true,
      isValidArea: true,
      valid: false,
      rules,

      // Contents
      reportItems: [
        {
          title: '¿Es demasiado larga?',
          headline: '',
          subtitle: ``,
          action: 'SI'
        },
        {
          title: '¿Contiene vocabulario complejo?',
          headline: '',
          subtitle: ``,
          action: 'SI'
        },
        {
          title: '¿No sabes que respuesta elegir?',
          headline: '',
          subtitle: ``,
          action: 'SI'
        },
        {
          title: 'Otro',
          headline: '',
          subtitle: ``,
          action: 'SI'
        }
      ],
      temperatureItems: [
        {
          title: 'Entre 36 y 37.5 ºC',
          headline: '',
          subtitle: ``,
          action: 'SI',
          semaphore: 'green'
        },
        {
          title: 'Entre 37.6 y 38 ºC',
          headline: '',
          subtitle: ``,
          action: 'SI',
          semaphore: 'amber'
        },
        {
          title: 'Más de 38 ºC',
          headline: '',
          subtitle: ``,
          action: 'SI',
          semaphore: 'red'
        }
      ],
      symptomsItems: [
        {
          name: 'shivering',
          title: 'Escalofríos o temblores',
          headline: '',
          subtitle: ``,
          yes: null
        },
        {
          name: 'headache',
          title: 'Dolor de cabeza',
          headline: 'también puedes presentar dolores musculares',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'throatPain',
          title: 'Dolor de garganta',
          headline: 'Dolor, picazón o ronquera',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'dryCough',
          title: 'Tos seca',
          headline: 'Sin flemas',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'breathDifficulty',
          title: 'Problemas para respirar',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'sneezing',
          title: 'Estornudos frecuentes',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'runnyNose',
          title: 'Congestión o escurrimientos nasales',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'malaise',
          title: 'Cansancio o debilidad inusuales',
          headline: 'Malestar general, fatiga',
          subtitle: ``,
          action: 'SI',
          yes: null
        }
      ],
      vaccineItems: [
        {
          title: 'Son reacción a la aplicación de la vacuna COVID-19',
          headline: '',
          subtitle: ``,
          report: `Haznos saber de algún problema con la pregunta`,
          yes: null
        }
      ],
      lifestyleItems: [
        {
          title: 'Has estado en contacto cercano',
          headline:
            '<span class="font-weight-medium">15 minutos cara a cara durante 2 horas en la misma habitación</span>',
          subtitle: `con un caso COVID-19 confirmado`,
          report: `Haznos saber de algún problema con la pregunta`,
          action: 'SI',
          canReset: false
        },
        {
          title: 'Has viajado u Hospedado',
          headline: 'en una región o país',
          subtitle: `afectado por la enfermedad de COVID-19`,
          report: `Haznos saber de algún problema con la pregunta`,
          action: 'SI',
          canReset: false
        }
      ],
      healthyRecomendationsItems: [
        {
          title:
            'Recuerda el uso constante del cubrebocas, durante el transporte, en tus lugares de trabajo y de regreso a tu domicilio. Cámbialo las veces que sea necesario.',
          action: '<span class="text-h2 identity--text">1</span>'
        },
        {
          title:
            'Realiza de manera constante el adecuado lavado de las manos y has uso del gel anti-bacterial, las veces que sea necesario.',
          action: '<span class="text-h2 identity--text">2</span>'
        },
        {
          title:
            'Conserva una sana distancia desde 1.5 a 2 metros, en tu lugar de trabajo, comedor, vestidores y demás lugares de la operación.',
          action: '<span class="text-h2 identity--text">3</span>'
        },
        {
          title:
            'Respeta el número máximo de personas en cada uno de los a lugares de trabajo.',
          action: '<span class="text-h2 identity--text">4</span>'
        }
      ],
      vaccineEffectsRecomendationsItems: [
        {
          title: 'Comunícate con tu Jefe Inmediato.',
          action: '<span class="text-h2 identity--text">1</span>',
          phoneRequired: false
        },
        {
          title: 'Descansa 3 días, y vuelve a intentarlo.',
          action: '<span class="text-h2 identity--text">2</span>',
          phoneRequired: false
        },
        {
          title:
            'Por favor, deja tu número de celular para que el servicio médico se comunique contigo.',
          action: '<span class="text-h2 identity--text">3</span>',
          phoneRequired: true
        }
      ],
      noHealthyRecomendationsItems: [
        {
          title:
            'Mantente en aislamiento del resto de personas no infectadas en casa.',
          action: '<span class="text-h2 identity--text">1</span>',
          phoneRequired: false
        },
        {
          title: 'Comunícate con tu Jefe Inmediato.',
          action: '<span class="text-h2 identity--text">2</span>',
          phoneRequired: false
        },
        {
          title:
            'Si te cuesta respirar o tienes fiebre alta; por favor, acude a urgencias.',
          action: '<span class="text-h2 identity--text">3</span>',
          phoneRequired: false
        },
        {
          title:
            'Por favor, deja tu número de celular para que el servicio médico se comunique contigo.',
          action: '<span class="text-h2 identity--text">4</span>',
          phoneRequired: true
        }
      ],
      inContactRiskRecomendationsItems: [
        {
          title:
            'Mantente durante 7 días aislado sin manifestación de síntomas.',
          action: '<span class="text-h2 identity--text">1</span>',
          phoneRequired: false
        },
        {
          title:
            'O bien mantente aislado durante 5 días y regresa a laborar presentando resultados negativos de PCR.',
          action: '<span class="text-h2 identity--text">2</span>',
          phoneRequired: false
        },
        {
          title: 'Comunícate con tu Jefe Inmediato.',
          action: '<span class="text-h2 identity--text">3</span>',
          phoneRequired: false
        },
        {
          title:
            'Por favor, deja tu número de celular para que el servicio médico se comunique contigo.',
          action: '<span class="text-h2 identity--text">4</span>',
          phoneRequired: true
        }
      ],
      inTravelingRiskRecomendationsItems: [
        {
          title:
            'Mantente durante 7 días aislado sin manifestación de síntomas.',
          action: '<span class="text-h2 identity--text">1</span>',
          phoneRequired: false
        },
        {
          title:
            'O bien mantente aislado durante 5 días y regresa a laborar presentando resultados negativos de PCR.',
          action: '<span class="text-h2 identity--text">2</span>',
          phoneRequired: false
        },
        {
          title: 'Comunícate con tu Jefe Inmediato.',
          action: '<span class="text-h2 identity--text">3</span>',
          phoneRequired: false
        },
        {
          title:
            'Por favor, deja tu número de celular para que el servicio médico se comunique contigo.',
          action: '<span class="text-h2 identity--text">4</span>',
          phoneRequired: true
        }
      ],
      inLifestyleRiskRecomendationsItems: [
        {
          title:
            'Mantente durante 7 días aislado sin manifestación de síntomas.',
          action: '<span class="text-h2 identity--text">1</span>',
          phoneRequired: false
        },
        {
          title:
            'O bien mantente aislado durante 5 días y regresa a laborar presentando resultados negativos de PCR.',
          action: '<span class="text-h2 identity--text">2</span>',
          phoneRequired: false
        },
        {
          title: 'Comunícate con tu Jefe Inmediato.',
          action: '<span class="text-h2 identity--text">3</span>',
          phoneRequired: false
        },
        {
          title:
            'Por favor, deja tu número de celular para que el servicio médico se comunique contigo.',
          action: '<span class="text-h2 identity--text">4</span>',
          phoneRequired: true
        }
      ]
    }
  },
  watch: {
    // ou(current, before) {
    //   this.oid = current
    //   if (before !== current && this.isStep('company')) {
    //     if (this.isValidCompany) {
    //       setTimeout(() => this.next(), 850)
    //     }
    //   }
    // },
    // temperatureSelected(current, before) {
    //   if (before !== current && this.isStep('temperature')) {
    //     if (this.isTemperatureChecked) {
    //       setTimeout(() => this.next(), 2400)
    //     }
    //   }
    // },
    menuVaccine(val) {
      val && setTimeout(() => (this.activeVaccinePicker = 'YEAR'))
    },
    symptomsItems: {
      deep: true,
      immediate: true,
      handler(items) {
        this.allSymptomsChecked = items.reduce((res, item) => {
          return res && isDef(item.yes)
        }, true)
        this.hasNoSymptoms = items.reduce((res, item) => {
          return res && isDef(item.yes) && !item.yes
        }, true)
        this.hasHeadache = items.find(isHeadache).yes
        this.hasRunnyNose = items.find(isRunnyNose).yes
        this.hasMalaise = items.find(isMalaise).yes
      }
    },
    vaccineItems: {
      deep: true,
      immediate: true,
      handler(items) {
        this.allVaccineChecked = items.reduce((res, item) => {
          return res && isDef(item.yes)
        }, true)
        this.isVaccinated = items.reduce((res, item) => {
          return res && isDef(item.yes) && item.yes
        }, true)
      }
    },
    isHealthy(now, before) {
      if (now && !before) {
        this.resetVaccineInterview()
      }
    },
    isVaccinated(now, before) {
      if (!now && before) {
        this.vaccineDate = null
      }
    },
    shouldInvalidCurrentAssessment(should) {
      if (should) {
        this.cleanAssessment()
        this.cleanPass()
        this.startOver()
        this.stopClock()
      }
    }
  },
  computed: {
    ...mapGetters('userOperator', ['uid', 'ou']),
    ...mapGetters('requestsOperators', [
      'isCallRequestSet',
      'hasValidPassCode',
      'canGenerateNewAssessmentByTimeout',
      'hasAssessment',
      'orgName',
      'party',
      'pass',
      'isSavedVaccineCase',
      'isSavedProbablyCovidCase',
      'isSavedContactCase',
      'isSavedTravelingCase',
      'isSavedContactAndTravelingCase'
    ]),
    // Party Data
    name: {
      set(name) {
        this.updateParty({ key: 'name', value: name })
      },
      get() {
        return this.party.name
      }
    },
    aid: {
      set(aid) {
        this.updateParty({ key: 'aid', value: aid })
      },
      get() {
        return this.party.aid
      }
    },
    aname: {
      set(aname) {
        this.updateParty({ key: 'aname', value: aname })
      },
      get() {
        return this.party.aname
      }
    },
    oid: {
      set(oid) {
        this.updateParty({ key: 'oid', value: oid })
      },
      get() {
        return this.party.oid
      }
    },
    code: {
      set(code) {
        this.updateParty({ key: 'code', value: code })
      },
      get() {
        return this.party.code
      }
    },
    phone: {
      set(phone) {
        this.updateParty({ key: 'phone', value: phone })
      },
      get() {
        return this.party.phone
      }
    },

    requestLoading() {
      return this.progressCircularValue > 0
    },

    // Session
    // isUserSignedIn() {
    //   return isDef(firebaseAuth.currentUser)
    // },
    // Cases conditions
    isHealthy() {
      return this.hasNormalBodyTemperature && this.hasNoSymptoms
    },
    isInRiskByLifestyle() {
      return this.lifestyleSelected.length > 0
    },
    wasInContact() {
      return this.lifestyleSelected.find(e => e === 0) !== undefined
    },
    wasOnTraveling() {
      return this.lifestyleSelected.find(e => e === 1) !== undefined
    },
    hasProbablyVaccineEffects() {
      return (
        this.hasNormalBodyTemperature &&
        (this.hasHeadache || this.hasRunnyNose || this.hasMalaise) &&
        this.isOnTimeForVaccineEffect
      )
    },
    canGenerateCurrentPassCode() {
      return this.isHealthy && !(this.wasInContact || this.wasOnTraveling)
    },
    canSendRequestForCall() {
      return (
        !this.canGenerateCurrentPassCode &&
        this.isValidPhone &&
        !this.requestForCallAck.sent
      )
    },
    isReadyByTimeoutForNextRequest() {
      return this.isRequestAllowed
    },

    // Cases Views
    passCodeCase() {
      return this.canGenerateCurrentPassCode || this.hasValidPassCode(this.now)
    },
    vaccineCase() {
      return (
        !this.waitingForNextCycle &&
        this.isVaccinated &&
        this.hasProbablyVaccineEffects &&
        !(this.wasInContact || this.wasOnTraveling)
      )
    },
    probablyCovidCase() {
      return !this.waitingForNextCycle && !this.isHealthy
    },
    contactCase() {
      return (
        !this.waitingForNextCycle && this.wasInContact && !this.wasOnTraveling
      )
    },
    travelingCase() {
      return (
        !this.waitingForNextCycle && this.wasOnTraveling && !this.wasInContact
      )
    },
    contactAndTravelingCase() {
      return (
        !this.waitingForNextCycle && this.wasInContact && this.wasOnTraveling
      )
    },

    // Conditions
    isValidCompany() {
      return isDef(this.ou) ? true : false
    },
    hasNormalBodyTemperature() {
      return this.temperatureSelected === 0
    },
    hasLightFever() {
      return this.temperatureSelected === 1
    },
    totalSymptomsTocheck() {
      return this.symptomsItems.length
    },
    isTemperatureChecked() {
      return isDef(this.temperatureSelected) ? true : false
    },
    isValidVaccine() {
      return (
        (this.isVaccinated && isDef(this.vaccineDate)) ||
        (!this.isVaccinated && this.allVaccineChecked)
      )
    },
    isOnTimeForVaccineEffect() {
      if (!isDef(this.vaccineDate)) {
        return false
      } else {
        const sinceFourteenDaysBefore = subDays(3 + 1)
        const fourteenDaysBeforeNow = sinceFourteenDaysBefore(new Date())
        const isInVaccineDaysEffects = isAfter(fourteenDaysBeforeNow)
        return isInVaccineDaysEffects(new Date(this.vaccineDate))
      }
    },
    isNextEnabled() {
      return (
        this.isStep('welcome') ||
        this.isStep('terms') ||
        (this.isStep('name') && this.isValidName) ||
        (this.isStep('company') && this.isValidCompany) ||
        (this.isStep('temperature') && this.isTemperatureChecked) ||
        (this.isStep('symptoms') && this.allSymptomsChecked) ||
        (this.isStep('vaccine') && this.isValidVaccine) ||
        this.isStep('lifestyle') ||
        (this.isStep('results') &&
          (this.canGenerateNewAssessmentByTimeout(this.now) ||
            (this.canSendRequestForCall && !this.isCallRequestSet) ||
            !this.isReadyByTimeoutForNextRequest))
      )
    },
    shouldInvalidCurrentAssessment() {
      return this.canGenerateNewAssessmentByTimeout(this.now)
    },
    isStep() {
      return name => {
        switch (name) {
          case 'welcome':
            return this.step === 1
          case 'terms':
            return this.step === 2
          case 'name':
            return this.step === 3
          case 'company':
            return this.step === 4
          case 'temperature':
            return this.step === 5
          case 'symptoms':
            return this.step === 6
          case 'vaccine':
            return this.step === 7
          case 'lifestyle':
            return this.step === 8
          case 'results':
            return this.step === 9

          default:
            return false
        }
      }
    },
    isPreviousToResultsView() {
      return this.step === TOTAL_STEPS - 1
    },
    isResultsView() {
      return this.step === TOTAL_STEPS
    },

    // Form
    nextLabel() {
      if (this.isStep('terms')) {
        return 'Acepto los términos'
      } else if (this.isStep('temperature') && !this.isTemperatureChecked) {
        return 'Selecciona una opción'
      } else if (this.isStep('temperature')) {
        return 'Siguiente'
      } else if (this.isStep('symptoms') && !this.allSymptomsChecked) {
        return this.$vuetify.breakpoint.mobile
          ? 'Contesta todas'
          : 'Contesta todas para continuar'
      } else if (this.isStep('symptoms')) {
        return 'Siguiente'
      } else if (this.isPreviousToResultsView) {
        return this.$vuetify.breakpoint.mobile ? 'Sí' : 'Sí, doy por manifiesto'
      } else if (this.isResultsView) {
        if (
          this.canGenerateCurrentPassCode ||
          this.hasValidPassCode(this.now)
        ) {
          // ^ A new pass code has been generated
          if (this.isNextEnabled && this.isRequestAllowed) {
            return 'Generar un nuevo pase'
          } else if (this.isNextEnabled && !this.isRequestAllowed) {
            return 'Cerrar'
          } else {
            // ^ The next btn is disabled
            if (this.hasValidPassCode(this.now) || this.hasAssessment) {
              // ^ It has already a result to be renewed after a period of time
              if (isDef(this.pass.effectiveDate) && this.now) {
                return `Nuevo · ${remainingTimeToNewPass(
                  this.now,
                  this.pass.effectiveDate
                )}`
              } else {
                // ^ Waiting for setting the assessment result
                return 'Recuperando...'
              }
            } else if (this.canGenerateNewAssessmentByTimeout(this.now)) {
              return ''
            } else if (this.isRequestAllowed) {
              // ^ Waiting for the server response
              return 'Generando pase ...'
            } else {
              return 'Esperando al mañana...'
            }
          }
        } else if (
          // ^ The assessment has been rejected
          !this.isHealthy &&
          !this.hasProbablyVaccineEffects
        ) {
          if (isDef(this.pass.effectiveDate) && this.now) {
            if (this.isCallRequestSet) {
              const nextMsg = `Nuevo · ${remainingTimeToNewPass(
                this.now,
                this.pass.effectiveDate
              )}`
              return this.$vuetify.breakpoint.mobile
                ? `Número enviado (${nextMsg})`
                : `Te llamaremos próximamente (${nextMsg})`
            } else {
              return this.$vuetify.breakpoint.mobile
                ? 'Enviar'
                : 'Escribe tu número arriba para enviarlo'
            }
          } else {
            // Waiting for the next period to ask for a new pass
            return this.waitingForNextCycle
              ? 'Recuperando...'
              : !this.isRequestAllowed
              ? 'Cerrar'
              : 'Solicitar un nuevo pase'
          }
        } else if (!this.isRequestAllowed) {
          // The user could close some dialogs but it is no time for the next cycle
          return 'Cerrar'
        } else {
          // Request to call
          if (this.isCallRequestSet) {
            return this.$vuetify.breakpoint.mobile
              ? 'Número enviado'
              : 'Te llamaremos próximamente'
          } else {
            return this.$vuetify.breakpoint.mobile
              ? 'Enviar'
              : 'Escribe tu número arriba para enviarlo'
          }
        }
      } else {
        return this.isStep('company') && !this.isValidCompany
          ? this.$vuetify.breakpoint.mobile
            ? 'Selecciona una opción'
            : 'Selecciona una compañía'
          : 'Siguiente'
      }
    },

    // Color
    checkColor: function() {
      return this.checked ? 'identity' : 'red accent-3'
    },
    semaphoreColor() {
      let color = 'black--text'
      if (this.hasLightFever && this.hasNoSymptoms)
        color = 'text--yellow text--accent-4'
      else if (this.isHealthy || this.hasValidPassCode(this.now))
        color = 'green--text text--darken-4'
      else if (this.isHealthy && this.isInRiskByLifestyle)
        color = 'orange--text'
      else color = 'red--text text--accent-3'
      return color
    },

    // Data
    formattedDateVaccine() {
      return this.vaccineDate
        ? longFormattedDate(`${this.vaccineDate}T06:00`)
        : this.vaccineDate
    },
    resultsTitle() {
      let title = ''
      if (
        this.isRequestAllowed &&
        (this.canGenerateCurrentPassCode || this.hasValidPassCode(this.now))
      ) {
        title = 'Presenta esta autorización de ingreso'
      } else if (!this.isRequestAllowed) {
        title = 'Aún no pasa el tiempo para que generes una nueva solicitud'
      } else if (!this.inPassCode) {
        title = 'Resultados'
      }
      return title
    },
    verifierCode() {
      return format('d')(new Date())
    }
  },
  methods: {
    // State
    ...mapActions('userOperator', ['initSession', 'updateOrg']),
    ...mapActions('requestsOperators', [
      'requestPass',
      'informResult',
      'requestForCall',
      'updateParty',
      'cleanAssessment',
      'cleanPass'
    ]),

    // To organize
    setRequestPermissionDenied(errorCode) {
      if (errorCode === 'permission-denied') {
        this.isRequestAllowed = false
      }
    },
    releaseRequestPermissionDenied() {
      this.isRequestAllowed = true
    },
    startProgressCircular() {
      this.progressInterval = setInterval(() => {
        if (this.progressCircularValue === 100) {
          return (this.progressCircularValue = 0)
        }
        this.progressCircularValue += 10
      }, 1000)
    },
    stopProgressCircular() {
      this.progressCircularValue = 0
      clearInterval(this.progressInterval)
    },
    resultsByState() {
      let results = []
      if (!this.isHealthy) {
        results.push('covid19')
      }
      if (this.hasProbablyVaccineEffects) {
        results.push('vaccine')
      }
      if (this.wasInContact) {
        results.push('contact')
      }
      if (this.wasOnTraveling) {
        results.push('traveling')
      }
      return results
    },

    // Help Desk
    ackRequestForCallSent() {
      this.requestForCallAck.sent = true
    },
    ackRequestForCallFailure() {
      this.requestForCallAck.failure = true
    },
    cancelReport() {
      this.reportSelected = []
    },

    // Slide View
    openInstrument() {
      if (!this.hasAssessment) {
        this.resetInput()
      }
      this.checked = true
      this.dialog = true
    },
    closeInstrument() {
      this.checked = false
      this.dialog = false
    },
    hideAllViewsASAP() {
      this.waitingForNextCycle = true
    },
    showPersistentResult() {
      if (this.hasAssessment) {
        // Auto update
        this.startMinueteClock()
        // Patch to hide all the views except the pass code results view
        if (this.hasValidPassCode(new Date())) {
          this.hideAllViewsASAP()
        }
        // Once all views are hide, go to the code view
        this.toResults()
        // Once results is the only visible: Open the dialog
        this.openInstrument()
      }
    },
    toTop() {
      document.getElementById('main').scrollIntoView({ behavior: 'smooth' })
    },
    toResults() {
      this.step = TOTAL_STEPS
    },
    next() {
      this.runApplicableActions()
      this.forward()
      this.toTop()
    },
    back() {
      this.backward()
      this.toTop()
    },
    forward() {
      if (this.isStep('name')) {
        if (this.isValidName) {
          this.step++
        }
      } else if (this.isStep('company')) {
        if (this.isValidCompany) {
          this.step++
        }
      } else if (this.isStep('temperature')) {
        if (this.isTemperatureChecked) {
          this.step++
        }
      } else if (this.isStep('symptoms')) {
        if (this.allSymptomsChecked && this.isHealthy) {
          this.resetVaccineInterview()
          this.skip()
        } else if (this.allSymptomsChecked) {
          this.step++
        }
      } else if (this.isStep('vaccine')) {
        if (this.isValidVaccine) {
          this.step++
        }
      } else if (this.isStep('results')) {
        if (!this.canSendRequestForCall || !this.isRequestAllowed) {
          this.resetInput()
          this.step++
        }
      } else {
        this.step++
      }
    },
    backward() {
      if (!this.isResultsView) {
        if (this.isStep('lifestyle') && this.isHealthy) {
          this.resetVaccineInterview()
          this.step = this.step - 2
        } else {
          this.step--
        }
      }
    },
    skip(num) {
      this.step = this.step + (isDef(num) ? num + 1 : 2)
    },
    startOver() {
      this.closeInstrument()
    },

    // Identity
    focusAreaField() {
      this.$refs.fieldArea.focus()
    },

    // Activity
    runApplicableActions() {
      this.ifTermsAcceptThem()
      this.ifLastStepSendRequest()
      this.ifOnResultsViewSendRequestToCall()
    },
    ifTermsAcceptThem() {
      if (this.isStep('terms')) {
        this.terms = true
      }
    },
    ifLastStepSendRequest() {
      if (this.isPreviousToResultsView && this.canGenerateCurrentPassCode) {
        this.startProgressCircular()
        this.unsubscribeListener = this.requestPass(this.requestData())
          .then(() => {
            this.releaseRequestPermissionDenied()
            this.startRealtimeClock()
            this.stopProgressCircular()
          })
          .catch(error => {
            this.setRequestPermissionDenied(error.code)
            this.stopProgressCircular()
          })
      } else if (
        this.isPreviousToResultsView &&
        !this.canGenerateCurrentPassCode
      ) {
        this.startProgressCircular()
        this.informResult(this.requestData())
          .then(() => {
            this.releaseRequestPermissionDenied()
            this.startRealtimeClock()
            this.stopProgressCircular()
          })
          .catch(error => {
            this.setRequestPermissionDenied(error.code)
            this.stopProgressCircular()
            console.log('inform pass:reject', error)
          })
      }
    },
    //@todo before commit check isRequestAllowed
    ifOnResultsViewSendRequestToCall() {
      if (
        this.isResultsView &&
        this.canSendRequestForCall &&
        this.isRequestAllowed
      ) {
        this.requestForCall(this.requestData())
          .then(() => {
            this.ackRequestForCallSent()
          })
          .catch(error => {
            console.log('request for call error', error)
            this.ackRequestForCallFailure()
          })
      }
    },

    // Clock
    startRealtimeClock() {
      this.startClock(REALTIME_UPDATES_FRECUENCY)
      // const slowdownTo = this.slowdownClockAfter(FIVE_MINUTES)
      // slowdownTo(SHORT_UPDATES_FRECUENCY)
    },
    startMinueteClock() {
      this.startClock(REALTIME_UPDATES_FRECUENCY)
      // const slowdownTo = this.slowdownClockAfter(FIVE_MINUTES)
      // slowdownTo(LONG_UPDATES_FRECUENCY)
    },
    slowdownClockAfter(after) {
      return frecuency => setTimeout(() => this.startClock(frecuency), after)
    },
    startClock(frecuency) {
      this.stopClock()
      this.interval = setInterval(() => {
        this.now = new Date()
      }, frecuency)
    },
    stopClock() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },

    // Data
    requestData() {
      return {
        result: authResult(this.canGenerateCurrentPassCode),
        results: this.resultsByState()
      }
    },
    resetInput() {
      this.requestForCallAck.sent = false
      this.requestForCallAck.failure = false

      this.terms = false
      this.temperature = []
      this.vaccineDate = null
      this.isVaccinated = false
      this.reportSelected = []
      this.temperatureSelected = null
      this.symptomSelected = []
      this.hasNoSymptoms = false
      this.vaccineSelected = []
      this.lifestyleSelected = []
      this.hasHeadache = null
      this.hasRunnyNose = null
      this.hasMalaise = null
      this.resetVaccineInterview()

      this.checked = false
      this.isValidPhone = false
      this.isValidName = true
      this.isValidArea = true
      this.valid = false
      this.dialog = false
      this.fab = false
      this.step = 1
      this.menuVaccine = false
      this.temperatureReportDialog = false
      this.symptomReportDialog = false
      this.allSymptomsChecked = false
      this.vaccineReportDialog = false
      this.allVaccineChecked = false
      this.lifestyleReportDialog = false
      this.symptomsItems = [
        {
          name: 'shivering',
          title: 'Escalofríos o temblores',
          headline: '',
          subtitle: ``,
          yes: null
        },
        {
          name: 'headache',
          title: 'Dolor de cabeza',
          headline: 'también puedes presentar dolores musculares',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'throatPain',
          title: 'Dolor de garganta',
          headline: 'Dolor, picazón o ronquera',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'dryCough',
          title: 'Tos seca',
          headline: 'Sin flemas',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'breathDifficulty',
          title: 'Problemas para respirar',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'sneezing',
          title: 'Estornudos frecuentes',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'runnyNose',
          title: 'Congestión o escurrimientos nasales',
          headline: '',
          subtitle: ``,
          action: 'SI',
          yes: null
        },
        {
          name: 'malaise',
          title: 'Cansancio o debilidad inusuales',
          headline: 'Malestar general, fatiga',
          subtitle: ``,
          action: 'SI',
          yes: null
        }
      ]
    },
    resetVaccineInterview() {
      this.vaccineItems.forEach(item => (item.yes = null))
      this.vaccineDate = null
    },
    capitalizeName() {
      if (this.name && this.name.split) {
        const words = this.name.split(' ')
        this.name = transformToVersales(words)
      }
    },
    capitalizeArea() {
      if (this.aname && this.aname.split) {
        const words = this.aname.split(' ')
        this.aname = transformToVersales(words)
      }
    },
    openQRCode() {
      this.$refs.qrcodedialog.open()
    },
    defaultCode(evt) {
      this.updateParty('code', defaultValue(evt, '+52'))
    },
    today() {
      return humanFormattedDate(new Date())
    },
    saveVaccine(date) {
      this.$refs.menuVaccine.save(date)
    },
    initFirebase() {
      // Auth listener
      onAuthStateChanged(firebaseAuth, user => {
        // auth.app.options.appId
        if (user) {
          // console.log(
          //   'onAuthStateChanged/additionalUserInfo',
          //   user.metadata,
          //   user.isAnonymous,
          //   user.displayName,
          //   user.providerData,
          //   user.providerId
          // )
          // user
          //   .updateProfile({
          //     displayName: 'Jane Q. User'
          //   })
          //   .then(
          //     function() {
          //       // Profile updated successfully!
          //       // "Jane Q. User"
          //       console.log('newprofileupdate', user.displayName)
          //     },
          //     function(error) {
          //       console.log('error updating profile', error)
          //     }
          //   )
          this.initSession({
            appId: 'avo',
            uid: user.uid
          })
        } else {
          // User is signed out
          console.log('user is signed out')
        }
      })

      // Sign in
      if (!isDef(firebaseAuth.currentUser)) {
        signInAnonymously(firebaseAuth).catch(error => {
          console.log('signIn-error', error.code, error.message)
        })
        enablePersistence()
      }
    },
    preventMemoryLeak() {
      this.stopClock()
      this.stopProgressCircular()
    }
  },
  created() {
    this.initFirebase()
    this.showPersistentResult()
  },
  beforeDestroy() {
    this.preventMemoryLeak()
    console.log(this.unsubscribeListener)
    isDef(this.unsubscribeListener) && this.unsubscribeListener()
  }
}
</script>

<style>
li {
  line-height: 1.4rem;
}
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
.spaced-numbers {
  letter-spacing: 0.3rem !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
