<template>
  <div>
    <h1
      v-if="withHeadings"
      class="text-h6 text-md-h4 mt-6"
      :class="fontWeightLightMd"
    >
      {{ $t('profile.ou.title') }}
    </h1>
    <v-item-group
      :multiple="multiple"
      active-class="primary"
      v-model="selected"
    >
      <v-row align="center" justify="space-around">
        <v-col :key="`card-${i}`" cols="6" md="3" v-for="(org, i) in allOrgs">
          <v-item :value="org.code" v-slot:default="{ active, toggle }">
            <v-card
              rounded="lg"
              :color="active ? 'identity white--text' : ''"
              @click="toggle"
              :min-width="$vuetify.breakpoint.mobile ? 110 : 105"
              max-width="150"
            >
              <v-scroll-y-transition>
                <div class="text-h2">
                  <v-img
                    class="align-self-stretch"
                    :src="managedImg(org.avatar)"
                    aspect-ratio="1.7"
                  >
                    <div class="fill-height bottom-gradient"></div>
                    <v-container class="pa-0" v-if="active">
                      <v-row align="end">
                        <v-row justify="center">
                          <div>
                            <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                          </div>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-img>
                  <v-card-text primary-title class="button d-block text-center">
                    <span class="d-block text-caption dark">{{
                      org.city
                    }}</span>
                    <h1 class="d-block" :class="siteNameStyle">
                      {{ org.name }}
                    </h1>
                  </v-card-text>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </div>
</template>

<script>
import { managedImg } from '@/micro/img'
import { siteNameText } from '@/micro/text'
import { mapGetters, mapActions } from 'vuex'
import { getOrgs } from '@/store/lite/orgs'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    initOu: {
      type: String,
      default: null
    },
    withHeadings: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('userOperator', ['ou']),
    allOrgs() {
      return getOrgs()
    },
    siteNameStyle() {
      return siteNameText(this.$vuetify.breakpoint)
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    selected: {
      set: async function(ou) {
        this.updateOrg(ou)
      },
      get: function() {
        return this.ou
      }
    }
  },

  methods: {
    managedImg,
    ...mapActions('userOperator', ['updateOrg'])
  },

  created() {
    this.selected = this.initOu
  }
}
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}
.av-card-btn {
  text-transform: uppercase;
}
</style>
